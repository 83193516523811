var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"teste",class:{
        'title-item-dark': _vm.$vuetify.theme.dark,
    },attrs:{"fluid":""}},[_c('v-card-actions',[_c('v-row',{staticClass:"mt-1"},[_c('v-col',[_c('btn-adicionar',{attrs:{"temPermissao":_vm.$store.getters['usuario/temPermissao'](
                            'inserir.usuarios'
                        ),"to":"/usuarios/adicionar"}})],1)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{class:{
                'title-item-dark': _vm.$vuetify.theme.dark,
            }},[_c('v-card',{attrs:{"elevation":"3"}},[_c('v-data-table',{attrs:{"disable-filtering":"","disable-sort":"","server-items-length":_vm.totalRegistros,"headers":_vm.cabecalho,"items":_vm.items,"options":_vm.paginacao,"footer-props":{
                        'items-per-page-options': [10, 20, 50],
                    }},on:{"update:options":[function($event){_vm.paginacao=$event},_vm.carregarUsuariosEmpresas]},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('div',{staticClass:"tables"},[_c('table-btn-mostrar',{attrs:{"temPermissao":_vm.$store.getters['usuario/temPermissao'](
                                        'mostrar.usuarios'
                                    ),"href":`/usuarios/${item.id}/mostrar`}}),_c('table-btn-editar',{attrs:{"temPermissao":_vm.$store.getters['usuario/temPermissao'](
                                        'editar.usuarios'
                                    ),"href":`/usuarios/${item.id}/editar`}})],1)]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }