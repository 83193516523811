<template>
    <v-container
        fluid
        class="teste"
        :class="{
            'title-item-dark': $vuetify.theme.dark,
        }"
    >
        
        <v-card-actions>
            <v-row class="mt-1">
                <v-col>
                    <btn-adicionar
                        :temPermissao="
                            $store.getters['usuario/temPermissao'](
                                'inserir.usuarios'
                            )
                        "
                        to="/usuarios/adicionar"
                    />
                </v-col>
            </v-row>
        </v-card-actions>
        <v-row class="mt-4">
            <v-col
                :class="{
                    'title-item-dark': $vuetify.theme.dark,
                }"
            >
                <v-card elevation="3">
                    <v-data-table
                        disable-filtering
                        disable-sort
                        :server-items-length="totalRegistros"
                        :headers="cabecalho"
                        :items="items"
                        :options.sync="paginacao"
                        :footer-props="{
                            'items-per-page-options': [10, 20, 50],
                        }"
                        @update:options="carregarUsuariosEmpresas"
                    >
                        <template
                            v-slot:[`item.actions`]="{ item }"
                            class="text-right"
                        >
                            <div class="tables">
                                <table-btn-mostrar
                                    :temPermissao="
                                        $store.getters['usuario/temPermissao'](
                                            'mostrar.usuarios'
                                        )
                                    "
                                    :href="`/usuarios/${item.id}/mostrar`"
                                />
                                <table-btn-editar 
                                    :temPermissao=" 
                                        $store.getters['usuario/temPermissao'](
                                            'editar.usuarios'
                                        )
                                    "
                                    :href="`/usuarios/${item.id}/editar`"
                                />
                            </div>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import empresas from "@/api/empresas";
import store from "@/store";

export default {
    data() {
        return {
            tab: null,
            permiteMostrar: store.getters["usuario/temPermissao"](
                "mostrar.usuarios"
            ),
           
            totalRegistros: 0,
            idExcluir: null,
            cabecalho: [
                { text: "#", value: "id" },
                { text: "Nome", value: "pessoa.nome" },
                { text: "Login", value: "login" },
                { text: "E-mail", value: "email" },
                { text: "Status", value: "status" },
                { text: "Ações", value: "actions", align: "right" },
            ],
            items: [],
            paginacao: {},
        };
    },

    methods: {
        async carregarUsuariosEmpresas() {
            try {
                this.$store.commit(`layout/SET_LOADING`, true);

                const empresa_id = this.$store.state.usuario.usuario.empresa_id;

                const resposta = await empresas.listarUsuariosEmpresa(
                    empresa_id,
                    {
                        pagina: this.paginacao.page,
                        por_pagina: this.paginacao.itemsPerPage,
                    }
                );

                this.items = resposta.data.data;
                this.items.forEach((item) => {
                    if (item.ativo == 1) {
                        item.status = "Ativo";
                    } else {
                        item.status = "Inativo";
                    }
                });

                this.totalRegistros = resposta.data.meta.total;
            } catch (e) {
                this.$snackbar.mostrar({
                    cor: "error",
                    mensagem: this.$erroApi._(e),
                });
            } finally {
                this.$store.commit(`layout/SET_LOADING`, false);
            }
        },
    },
};
</script>
<style scoped>
.tables {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
</style>
